export default function MarketingAutomation(){
    return(
        <body className="bg-primary min-h-screen text-secondary font-sedan">
            {/* intro icon */}
            <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
            
                <img src="/images/automation-banner.jpg" className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"></img>
                

                <div className="w-[30%] max-md:w-[80%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12 border-l-4 border-complementary h-fit  px-8">
                    <h1 className="text-6xl max-md:text-5xl leading-[75px] font-semibold">  Marketing Automation</h1>
                </div>
            </div>
            <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center  ">
                    <h1 className="text-5xl max-md:text-4xl font-bold text-center"><span className="text-complementary">Automation</span> can set your business apart with no additional <span className="text-complementary">work</span></h1>
                    <h1 className="text-2xl max-md:text-xl text-center font-semibold">How can market automation boost business productivity?</h1>
                    <p className="text-lg max-md:text-sm">Marketing Automation revolutionizes business efficiency by streamlining repetitive tasks, such as email campaigns and appointment reminders, which were previously time-consuming and unmanageable manually. Through sophisticated software, Automation swiftly delivers targeted content, leveraging Artificial Intelligence (AI) and Machine Learning (ML) for enhanced campaign effectiveness and rapid execution.</p>
                    <p className="text-lg max-md:text-sm">This approach enables personalized engagement with users while tracking their behavior, interests, and intent. Insights gathered by the automation software continuously refine campaign performance.</p>
                    <p className="text-lg max-md:text-sm">Ultimately, Marketing Automation aims to engage customers effectively, driving desired results and conversions. These powerful tools, including HubSpot, Mailchimp, Monday.com, ActiveCampaign, and Sendinblue, are pivotal for businesses seeking a competitive edge.</p>
                </div>
        </body>
    )
}