export default function MarketingAttribution(){
    return(
        <body className="bg-primary min-h-screen text-secondary font-sedan">
            {/* intro icon */}
            <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
            
                <img src="/images/attribution-banner.jpg" className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"></img>
                

                <div className="w-[30%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12 border-l-4 border-complementary h-fit  px-8">
                    <h1 className="text-6xl max-md:text-5xl leading-[75px] font-semibold">  Marketing Attribution</h1>
                </div>
            </div>
            <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center  ">
                    <h1 className="text-5xl max-md:text-4xl font-bold text-center"><span className="text-complementary">Attribution</span> allows your business to understand your <span className="text-complementary">ROAs</span> better.</h1>
                    <h1 className="text-2xl font-semibold text-center max-md:text-xl">What is attribution and what are its benefits?</h1>
                    <p className="text-lg max-md:text-sm">Marketing attribution is the process of tracing a customer’s journey from awareness to purchase to determine which marketing efforts contributed to the sale. It offers several benefits, including optimizing investment in high-performing tactics, understanding consumer behavior, and tailoring messaging to individual preferences.</p>
                    <p className="text-lg max-md:text-sm">However, attribution is intricate and never fully precise due to the complexity of modern consumer behavior. With consumers engaging across multiple devices and channels, assigning credit to a single tactic becomes challenging. This decision impacts budget allocation and underscores the importance of attribution in distinguishing effective strategies from wasteful spending</p>
                    <p className="text-lg max-md:text-sm">Furthermore, tracking consumer behavior, particularly offline and amid evolving privacy regulations, presents additional challenges. Effective automation requires comprehensive data analysis across various platforms and reports</p>
                    <p className="text-lg max-md:text-sm">Despite its complexity, marketing attribution is essential for businesses of all sizes and growthstages. Tools like <span className="text-complementary font-semibold">Attribution, Bizible, Hive9, Windsor.ai, C3 Metrics, and Dreamdata</span> help navigate this intricate landscape.</p>
                </div>
        </body>
    )
}