export default function About(){
    return(
        <div name="About" className="bg-primary relative min-h-screen text-secondary grid grid-cols-2 max-md:grid-cols-1">
            
            <img src="/images/about-banner.jpg" className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"></img>
            

            <div className=" justify-center items-center flex flex-col gap-12 p-12 max-md:py-8 max-md:p-4 ">
                <h1 className="text-5xl max-md:text-3xl font-semibold self-start"><span className="text-complementary">|</span>  BZ Marketing</h1>
                <h1 className="text-md max-md:text-sm"> Welcome to BZ Marketing, where innovation meets impact. We're a dynamic team dedicated to propelling your brand forward through cutting-edge strategies and unparalleled creativity. Let's transform your vision into reality.</h1>
                <h1 className="text-md max-md:text-sm">Established in the heart of New York City in 2020, BZ Marketing has emerged as a leading marketing agency dedicated to driving unparalleled growth for our clients. With a laser focus on maximizing sales, increasing website traffic, and expanding brand reach, we specialize in crafting innovative strategies that resonate with diverse audiences. From established enterprises to emerging startups, we're committed to propelling brands forward in the ever-evolving landscape of digital and traditional marketing.</h1>
                
            </div>
        </div>
    )
}