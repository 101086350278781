import { Link } from "react-scroll"
import { Link as NavLink } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faL } from "@fortawesome/free-solid-svg-icons"

export default function Navbar(){

    const [blogsDropdown, setBlogsDropdown] = useState(false)
    const [mobileNavbar, setMobileNavbar] = useState(false)
    return(
        <nav className="absolute top-0 w-[100%] z-50 font-sedan">
            <div className=" z-30  w-[100%] bg-opacity-35 bg-primary fixed  p-2 px-12 flex justify-between text-lg text-secondary items-center drop-shadow-xl ">
                <NavLink onClick={()=>setMobileNavbar(false)} to="/" className="text-3xl font-bold sticky max-md:text-2xl">
                    <img className="h-14 rounded-md" src="/images/logo.jpg"></img>
                </NavLink>
                <FontAwesomeIcon onClick={()=>setMobileNavbar(!mobileNavbar)} className="md:hidden h-8 cursor-pointer" icon={faBars}></FontAwesomeIcon>
                <div className="max-md:hidden flex justify-center gap-6  items-center text-md">
                    <Link to = "About" smooth = {true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-complementary  hover:cursor-pointer hover:justify-start hover:flex ">About</Link>
                    <Link to = "Services" smooth = {true} duration = {500} className="p-2  hover:border-b-2 hover:border-solid hover:border-complementary hover:cursor-pointer hover:justify-start hover:flex ">Services</Link>
                    {/* <Link to = "Testimonials" smooth = {true} duration = {500} className="p-2  hover:border-b-2 hover:border-solid hover:border-complementary hover:cursor-pointer hover:justify-start hover:flex ">Testimonials</Link> */}
                    <div className="">
                        <h1  onMouseEnter={()=>setBlogsDropdown(true)}  smooth = {true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-complementary  hover:cursor-pointer hover:justify-start hover:flex ">Blog</h1>
                        {blogsDropdown && (<div onMouseLeave={()=>setBlogsDropdown(false)} className="flex flex-col absolute top-[100%] text-lg text-primary bg-secondary">
                            <NavLink to="/blog/marketing-automation" className="hover:bg-complementary duration-300 px-4 p-3">Marketing Automation</NavLink>
                            <NavLink to="/blog/marketing-attribution"  className="hover:bg-complementary duration-300 px-4 p-3">Marketing Attribution</NavLink>
                            <NavLink to="/blog/growth-stages"  className="hover:bg-complementary duration-300 px-4 p-3">Growth Stages</NavLink>
                            <NavLink to="/blog/marketing-research"  className="hover:bg-complementary duration-300 px-4 p-3">Marketing Research</NavLink>
                        </div>)}
                    </div>
                    
                </div>
                <Link to="Contact" smooth = {true} duration={500} className="max-md:hidden justify-center items-center flex rounded-sm bg-complementary p-3 px-10 hover:scale-110 duration-500 hover:cursor-pointer text-primary">Contact</Link>
            </div>
            {mobileNavbar &&
                (
                <div className=" md:hidden fixed w-[100%]    flex flex-col justify-center gap-6 bg-primary h-screen text-secondary  items-center text-md max-md:text-xl">
                    <Link onClick={()=>setMobileNavbar(false)} to = "About" smooth = {true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-complementary  hover:cursor-pointer hover:justify-start hover:flex ">About</Link>
                    <Link onClick={()=>setMobileNavbar(false)} to = "Services" smooth = {true} duration = {500} className="p-2  hover:border-b-2 hover:border-solid hover:border-complementary hover:cursor-pointer hover:justify-start hover:flex ">Services</Link>
                    {/* <Link onClick={()=>setMobileNavbar(false)} to = "Testimonials" smooth = {true} duration = {500} className="p-2  hover:border-b-2 hover:border-solid hover:border-complementary hover:cursor-pointer hover:justify-start hover:flex ">Testimonials</Link> */}
                    
                    <h1 onClick={()=>setBlogsDropdown(!blogsDropdown)} smooth = {true} duration={500} className="p-2 hover:border-b-2 hover:border-solid hover:border-complementary  hover:cursor-pointer hover:justify-start hover:flex ">Blog</h1>
                    

                    {blogsDropdown && (<div onClick={()=>setMobileNavbar(false)} className="flex flex-col text-lg text-secondary text-opacity-50 gap-2 text-center ">
                        <NavLink to="/blog/marketing-automation" className="underline hover:text-complementary ">Marketing Automation</NavLink>
                        <NavLink to="/blog/marketing-attribution"  className="underline hover:text-complementary ">Marketing Attribution</NavLink>
                        <NavLink to="/blog/growth-stages"  className="underline hover:text-complementary">Growth Stages</NavLink>
                        <NavLink to="/blog/marketing-research"  className="underline hover:text-complementary">Marketing Research</NavLink>
                        
                    </div>)}
                    
                    
                    <Link onClick={()=>setMobileNavbar(false)} to="Contact" smooth = {true} duration={500} className="justify-center items-center flex rounded-sm bg-complementary p-3 px-10 hover:scale-110 duration-500 hover:cursor-pointer text-primary">Contact</Link>
                    
                    
                </div>
            )}
        </nav>
    )
}