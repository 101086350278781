export default function BrandStatement(){
    return(
        <div className="bg-secondary bg-opacity-5 relative h-screen text-secondary flex justify-center items-center">
            
            <div className=" justify-center items-center flex flex-col gap-12 p-12 px-60 max-md:p-4 ">
                <h1 className="text-5xl font-bold max-md:text-3xl text-center"><span className="text-complementary">Marketing</span> Solutions to <span className="text-complementary">Expand</span> Your <span className="text-complementary">Brand</span></h1>
                <h1 className="text-xl max-md:text-sm text-center">How can the right marketing approach help me reach my business objectives?</h1>
                <p className="text-lg max-md:text-sm">Whether your brand is freshly launched or boasts a decade of experience, selecting the right marketing strategy is paramount. Your marketing initiatives should align closely with the aims of the company. Whether it’s driving sales, enhancing brand recognition, expanding into new markets, or other specific goals, BZ marketing has a committed plan tailored to your specific needs. Efficiency is key in our process; your campaigns should yield maximum returns with minimal costs.</p>
                <p className="text-lg max-md:text-sm">Numerous factors shape your marketing approach, including business size, growth stage, advertising budget, and specific goals. However, ensuring your current strategies yield optimal results requires a deeper examination.</p>
                
            </div>
        </div>
    )
}