import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Service({tool, desc, icon}){
    return(
        <div className="flex flex-col gap-8 justify-center items-center text-center ">
            <FontAwesomeIcon className="h-16 max-md:h-8" icon={icon}></FontAwesomeIcon>
            <h1 className="text-2xl font-semibold max-md:text-xl">{tool}</h1>
            <h3 className="text-md max-md:text-sm">{desc}</h3>
        </div>
    )
}