import { faChartSimple, faPerson, faStore, faUsers } from "@fortawesome/free-solid-svg-icons";
import Service from "./Service";

export default function Services(){
    return(
        <div name="Services" className="bg-primary relative min-h-screen text-secondary flex justify-center items-center ">
            
            

            <div className=" justify-center items-center flex flex-col gap-12 p-12 max-md:p-4 ">
                <h1 className="text-5xl max-md:text-4xl font-bold">Services</h1>
                <h1 className="text-3xl max-md:text-xl font-semibold text-complementary">BZ Marketing</h1>
                <div className="grid grid-cols-3 gap-8 gap-x-24 max-md:gap-x-4 max-md:grid-cols-1 items-start">
                    <Service tool={"Marketing Analysis and Strategic Plan"} desc={"The first step of the Perfect Marketing Strategy entails analyzing the business and the market it competes in."} icon={faChartSimple}></Service>
                    <Service tool={"Execution of Marketing Plan"} desc={"The second step, once we have understood the way forward, is setting the business foundations up for success, while paving a way to grow and scale your business."} icon={faStore}></Service>
                    <Service tool={"Management and Consulting"} desc={"BZ Marketing will assist you with the implementation of the Perfect Marketing Strategy by advising you and your team how to proceed throughout the process."} icon={faUsers}></Service>
                </div>
            </div>
        </div>
    )
}