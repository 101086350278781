export default function MarketingResearch(){
    return(
        <body className="bg-primary min-h-screen text-secondary font-sedan">
            {/* intro icon */}
            <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
            
                <img src="/images/database-banner.jpg" className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"></img>
                

                <div className="w-[30%] max-md:w[80%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12 border-l-4 border-complementary h-fit  px-8">
                    <h1 className="text-6xl max-md:text-5xl leading-[75px] font-semibold">  Marketing Research Database</h1>
                </div>
            </div>
            <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center  ">
                <h1 className="text-5xl max-md:text-4xl font-bold text-center"><span className="text-complementary">Research</span> is critical to inform businesses on the best way to attack their <span className="text-complementary">goals</span> </h1>
                <h1 className="text-2xl font-semibold text-center max-md:text-xl">Marketing Reports and Statistics</h1>
                <p className="text-lg">At BZ Marketing, we prioritize marketing research to help clients achieve their business goals. Explore our latest reports and articles for valuable insights into global and local marketing trends. Click on the images to download the full reports. Enjoy!</p>
                

            </div>
            <div className="grid grid-cols-2 gap-y-16 justify-center items-center px-44 max-md:px-4">
                <img src="/images/nielsen-banner.jpg"  className="object-cover h-[250px] w-[400px] max-md:w-[150px] max-md:h-[150px]"></img>
                <div className="flex flex-col gap-8 text-lg max-md:text-sm">
                    <h1>Check out these key insights from Nielsen’s 2022 global marketing trends report, based on responses from 1,900+ marketers:</h1>
                    <ul>
                        <li>- Brand awareness is crucial</li>
                        <li>- Personalized marketing strategies leverage data</li>
                        <li>- Integrated measurement is essential</li>
                        <li>- Your brand must reflect your promise</li>
                    </ul>
                </div>
                <img src="/images/socialmedia-banner.jpg"  className="object-cover h-[250px] w-[400px] max-md:w-[150px] max-md:h-[150px]"></img>
                <div className="flex flex-col gap-8 text-lg max-md:text-sm">
                    <h1>In the APAC region, a USD $1 trillion opportunity is forecasted for 2025, up from USD $500 billion today. Explore the concept of ‘shoppertainment’ - content-driven commerce enriching shopping experiences.</h1>
                    
                </div>
                <img src="/images/europeaneccomerce-banner.jpg"  className="object-cover h-[250px] max-md:w-[150px] max-md:h-[150px] w-[400px]"></img>
                <div className="flex flex-col gap-8 text-lg max-md:text-sm">
                    <h1>Don’t miss the European Ecommerce Report 2023, offering invaluable insights for companies marketing to Europe. Additionally, discover Accenture’s Consumer Behavior Report, highlighting shifting consumer motivations and tendencies.</h1>
                    
                </div>
                <img src="/images/flywheel-banner.jpg"  className="object-cover h-[250px] w-[400px] max-md:w-[150px] max-md:h-[150px]"></img>
                <div className="flex flex-col gap-8 text-lg max-md:text-sm">
                    <h1>Learn about the Community Flywheel - a strategy for digital engagement and loyalty - explored in McKinsey & Company’s insightful article.</h1>
                    
                </div>
            </div>
        </body>
    )
}