import { Link } from "react-scroll"

export default function Intro(){
    return(
        <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
            
            <img src="/images/main-home-banner.jpg" className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"></img>
            

            <div className="w-[40%] max-md:w-[60%] absolute justify-center top-0 bottom-0 left-24 max-md:left-10 m-auto flex flex-col gap-12  ">
                <h1 className="text-5xl font-semibold"><span className="text-complementary">|</span>  BZ Marketing</h1>
                <h1 className="text-3xl max-md:text-xl font-semibold">Empowering Brands, Elevating Results - Where Innovation Meets Impact</h1>
                <Link to="Contact" smooth={true} duration={500} className="max-md:text-xl hover:scale-105 hover:border-secondary hover:cursor-pointer duration-500 border-complementary border-2 p-4 px-6 rounded-sm w-fit text-2xl ">Get in Touch</Link>
            </div>
        </div>
    )
}