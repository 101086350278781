export default function GrowthStages(){
    return(
        <body className="bg-primary min-h-screen text-secondary font-sedan">
            {/* intro icon */}
            <div className="bg-primary relative h-screen text-secondary flex justify-center items-center">
            
                <img src="/images/growth-banner.jpg" className="h-[100%] w-[100%] object-cover bg-blend-overlay opacity-50"></img>
                

                <div className="w-[30%] max-md:w-[80%] absolute justify-center top-0 bottom-0 left-24 m-auto flex flex-col max-md:left-10 gap-12 border-l-4 border-complementary h-fit  px-8">
                    <h1 className="text-6xl max-md:text-4xl leading-[75px] font-semibold ">  Business Growth Stages</h1>
                </div>
            </div>
            <div className="p-32 px-64 max-md:px-8 flex flex-col gap-8 items-center justify-center text-lg max-md:text-sm  ">
                    <h1 className="text-5xl max-md:text-4xl font-bold text-center"><span className="text-complementary">Select</span> the best marketing strategy for your individual <span className="text-complementary">growth</span> stage</h1>
                    <h1 className="text-2xl max-md:text-xl text-center font-semibold">What are the main business growth stages?</h1>
                    <h1 className="text-3xl font-semibold self-start max-md:text-2xl">First Stage: Set Up</h1>
                    <p className="text-lg max-md:text-sm">In the nascent stages of the business, foundational elements have been established, and initial traction is evident following successful concept validation and model testing. Priority lies in crystallizing the brand’s identity and value proposition, ensuring seamless platform functionality, and augmenting brand awareness and engagement to attract new customers. Concurrently, diligent reflection on the customer journey is imperative. Given the constrained financial resources typical of this phase, agility is paramount, as errors can incur significant costs.</p>
                    <h1 className="text-3xl font-semibold self-start max-md:text-2xl">Second Stage: Growth</h1>
                    <p className="">As the business expands its presence in a targeted market, the primary focus shifts toward customer acquisition and retention. Enhanced insights into the customer journey and segmentation emerge, facilitated by robust databases and automation tools. This strategic data informs tailored campaigns for optimal engagement and conversion, while also guiding future marketing strategies. While growth typically brings increased financial resources, prudent evaluation of all marketing initiatives remains imperative, with heightened attention to attribution. Moreover, this stage often entails team expansion and the cultivation of organizational culture.</p>
                    <p className="self-start ">Notable examples of companies in this growth phase include tech firms such as <span className="text-complementary font-semibold">Autopilot, Bopple, and Jarvis</span> </p>
                    <h1 className="text-3xl font-semibold self-start max-md:text-2xl">Third Stage: Expansion</h1>
                    <p className="">After achieving success in one region, expanding into new territories is the next strategic move. This phase demands meticulous planning and adherence to best practices to avoid costly errors. While the brand may be established locally, ensuring consistent representation of brand identity and values in new markets poses the primary challenge.</p>
                    <p className="">Deciding which market to enter next and how to do so—whether independently, through partnership, or acquisition—is a critical decision. Understanding how the target audience will perceive the brand based on their existing consumer behavior is essential.</p>
                    <p className="">Each approach carries implications that necessitate thoughtful deliberation. For instance, electric scooter companies like Segway and Razor, initially prominent in Europe, are now expanding into the US and other territories, exemplifying this phase of growth.</p>
                    <h1 className="text-3xl font-semibold self-start max-md:text-2xl">Fourth Stage: Maturity and Renewal</h1>
                    <p className="">The business has been operating for years, it is established and well known. It has however reached a stage where growth is minimal and the risks of competitors gaining much praised market share are evident. Furthermore, there is the risk that the product or service that is being offered may become obsolete or not as relevant as it used to be. This phase requires a clear understanding of how the market is shifting and how competitors are evolving. Where is the company now and what is it doing to stay relevant? An example of a company in this phase is Disney and how Disney Plus was introduced to stay relevant.</p>
                    <p className="self-start">Contact BZ Marketing today to see how we can assist you at every stage of your business growth.</p>
                </div>
        </body>
    )
}